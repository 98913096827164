import React from 'react'
import { Helmet } from '../components/Shared'
import PrivacyPolicy, { helmetProps } from '../components/Policy/Privacy'

export default React.memo(() => (
  <>
    <Helmet {...helmetProps} />
    <PrivacyPolicy />
  </>
))
