import React from 'react'
import { Link } from 'gatsby'
import helmetProps from './helmetProps'
import './PrivacyPolicy.global.scss'
import { Layout } from '../../Shared'
import {
  irisOfficeAddress,
  irisTopLineOfficeAddress,
  irisBottomLineOfficeAddress,
  wildOfficeAddress,
  wildTopLineOfficeAddress,
  WildBottomLineOfficeAddress,
} from '../../../utils/constants'

const PrivacyPolicy = () => (
  <div id="privacy">
    <div className="content container">
      <div className="intro">
        <p className="main-header">IrisVR Privacy Policy</p>
        <p>Effective Starting: May 25, 2018</p>
        <p className="subheader">Updated: February 19, 2021</p>
        <p>
          We are committed to safeguarding the privacy of our website visitors,
          product users, and service users. This policy applies where we are
          acting as a data controller with respect to the personal data of
          website visitors and users.
        </p>
        <p>
          Wild Technology, Inc. (“IrisVR,” “we,” or “us”) respects your rights
          with regard to your privacy, personal information and any proprietary
          content that you provide to your account or our software products,
          including all files and images (“Content”). This Privacy Policy
          explains how we collect, store, use, transfer, and retain your
          information and proprietary content, as well as how you can access and
          update your information and choices. This Privacy Policy also explains
          how we interact with third parties, such as online and offline service
          providers who help us operate our business or provide products and
          services to you. Certain parts of our websites, products or services
          may include supplemental privacy notices or in special cases have
          their own privacy policies.
        </p>
        <p>
          By visiting our websites and using our products and services, you
          agree to be bound by the terms and conditions of this Privacy Policy.
          If you do not agree, please do not use or access IrisVR websites,
          products, or services.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Personal and Non-Personal Information</p>
        <p>
          We collect both personal information and non­personal information from
          you through our products, services, and websites, from third parties,
          and from offline communications.
        </p>
        <p>
          “Personal information” is information that personally identifies you
          and allows us to contact you on­ or off­line, such as your name,
          e­mail address, postal address, telephone numbers, fax numbers,
          account numbers, and financial information.
        </p>
        <p>
          “Non-personal information” is information that does not identify you
          as a specific person or allow us to contact you, such as demographic
          information (geographic location, profession, language preference),
          technical information (browser, operating system, Internet navigation
          data), and other similar categories of information.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Collection of Information</p>
        <p>
          <span className="emphasis">Information you provide to us.</span> We
          receive personal and non­personal information from you when you create
          an account; create login credentials; e­mail, call, or send us postal
          mail; submit any webform on our website; interact with us through
          social media platforms; download, activate or access a product or
          service; or submit a support request.
        </p>
        <ul>
          <li>
            <span className="emphasis">Website Usage Data</span>: We may process
            data about your use of our website and services. The usage data may
            include your IP address, geographical location, browser type and
            version, operating system, referral source, length of visit, page
            views and website navigation paths, as well as information about the
            timing, frequency and pattern of your service use. The source of the
            usage data is Google Analytics, Visual Website Optimizer, Hubspot,
            and Squarespace. This usage data may be processed for the purposes
            of analyzing and improving our website and services. The legal basis
            for this processing is our legitimate interests, namely monitoring
            and improving our website and services.
          </li>
          <li>
            <span className="emphasis">Product Usage Data</span>: We may process
            data about your use of our products. The usage data may include your
            length of use, frequency of use, feature selection, file size, file
            type, and product tier, as well as information about the patterns of
            your use. We use third party analytics services such as Segment and
            Unity3D Analytics to help us analyze how users use the product and
            enhance your experience. Where required by applicable law, the basis
            for this processing is consent.
          </li>
          <li>
            <span className="emphasis">Account Data</span>: We may process your
            account data. The data may include your name, email address,
            industry, company name, location, and your access to hardware. The
            source of the account data is you or your employer. The account data
            may be processed for the purposes of operating our website,
            providing our services, ensuring the security of our website and
            services, maintaining back-ups of our databases and communicating
            with you. The legal basis for this processing is our legitimate
            interests, namely the proper administration of our website and
            business.
          </li>
          <li>
            <span className="emphasis">Enquiry Data</span>: We may process
            information contained in any enquiry you submit to us regarding
            goods and/or services. The enquiry data may be processed for the
            purposes of offering, marketing, supporting, and selling relevant
            goods and/or services to you. The legal basis for this processing is
            consent.
          </li>
          <li>
            <span className="emphasis">Customer Relationship Data</span>: We may
            process information relating to our customer relationships,
            including customer contact information. The customer relationship
            data may include your name, your employer, your job title or role,
            your contact details, and information contained in communications
            between us and you or your employer. The source of the customer
            relationship data is you or your employer. The customer relationship
            data may be processed for the purposes of managing our relationships
            with customers, communicating with customers, keeping records of
            those communications and promoting our products and services to
            customers. The legal basis for this processing is our legitimate
            interests, namely the proper management of our customer
            relationships.
          </li>
          <li>
            <span className="emphasis">Transaction Data</span>: We may process
            information relating to transactions, including purchases of goods
            and services, that you enter into with us and/or through our
            website. The transaction data may include your contact details and
            the transaction details. The transaction data may be processed for
            the purpose of supplying the purchased goods and services and
            keeping proper records of those transactions. The legal basis for
            this processing is the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract
            and our legitimate interests, namely the proper administration of
            our website, products, and business.
          </li>
          <li>
            <span className="emphasis">Notification Data</span>: We may process
            information that you provide to us for the purpose of subscribing to
            our email notifications, newsletters, and/or product education
            campaigns. The notification data may be processed for the purposes
            of sending you the relevant notifications, articles, tips, and/or
            newsletters. Where required by applicable law, the basis for this
            processing is consent.
          </li>
        </ul>
        <p>
          <span className="emphasis">Supplemental information.</span> We may
          supplement personal information you provide to us or that we collect
          from you with information from third-party sources that we deem
          credible. These may include ZoomInfo (
          <a href="https://www.zoominfo.com/about-zoominfo/privacy-center">
            https://www.zoominfo.com/about-zoominfo/privacy-center
          </a>
          ), Clearbit (
          <a href="https://clearbit.com/privacy">
            https://clearbit.com/privacy
          </a>
          ), and Hubspot (
          <a href="https://legal.hubspot.com/privacy-policy">
            https://legal.hubspot.com/privacy-policy
          </a>
          ). This usage data may be processed to ensure our records are
          accurate, to personalize our marketing efforts or to customize or
          enhance products or services offered to you. Where required by
          applicable law, the basis for this processing is consent.
        </p>
        <p>
          <span className="emphasis">
            Cookies and other automatic data collection tools.
          </span>{' '}
          When you access our websites and services through a browser,
          application, or other client, or when you communicate with us through
          e-mail or webforms, our servers automatically collect and record
          certain information. These tools help make your visit to our website
          easier and more efficient by recognizing you when you return and
          providing you with a customized experience. IrisVR and its service
          providers may use automatic data collection tools such as cookies,
          embedded web links, and other technologies to collect information.
          These data collection tools collect standard information from your
          browser such as browser type, browser language, and the website
          address you came from when you visited our website. They may also
          collect information about your Internet Protocol (IP) address,
          navigation data (such as the pages you view and the links you click),
          and the date and time of your visit or request. You can read the full
          policy here:{' '}
          <Link to="/cookies-policy">Cookies and Tracking Notice</Link>.
        </p>
        <p>
          <span className="emphasis">Payment Information.</span> We use a
          third-party payment processor, Stripe, to handle all payments made by
          you. IrisVR does not collect, store or process credit card
          information. Like all third-party service providers, our payment
          processor vendor is independently responsible for the personal
          information you share and while IrisVR has chosen partners with
          trusted security and compliance practices, we cannot guarantee that
          our payment processor will be error-free. You can find information
          about the payment services providers&#39; privacy policies and
          practices at{' '}
          <a href="https://stripe.com/us/privacy/">
            https://stripe.com/us/privacy/
          </a>
          .
        </p>
        <p>
          <span className="emphasis">Children&#39;s Privacy.</span> We do not
          knowingly collect any personal information from children aged 12 years
          old and under. In some jurisdictions, notification, prior written
          permission or other verifiable parental consent may be required of a
          parent or guardian for children from 13 to 17 years of age to provide
          information to us.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Use of Information and Content</p>
        <p>We may use your personal information in the following ways:</p>
        <ul>
          <li>to deliver a product or service you have requested.</li>
          <li>to contact you.</li>
          <li>
            to provide you with product registration, activation, and customer
            support.
          </li>
          <li>
            to allow you to create an account and log-in credentials, personal
            profiles and upload or share Content.
          </li>
          <li>
            to conduct surveys in order to provide better products and services
            to you.
          </li>
          <li>
            to send you information about IrisVR, our products and services,
            targeted marketing, and promotional offers, subject to your
            communication choices.
          </li>
          <li>
            to send you information from other companies about their products
            and services, subject to your communication choices.
          </li>
          <li>
            to send you operational communications, product or service notices
            communicating actions you should take regarding a product or
            service.
          </li>
          <li>
            to allow you to use a “tell a friend,” “forward to a friend,” or
            similar features that forward information you select to another
            individual. Note that if you invite or are invited to use Prospect,
            IrisVR typically stores all invitee contact name and email address,
            however in all cases IrisVR will use referral e­mail address and
            contact name (if applicable) for the sole purpose of sending a
            one­time e­mail on your behalf.
          </li>
          <li>
            to customize and improve our products, services, content, and
            marketing.
          </li>
          <li>
            to personalize your overall experience with IrisVR, our websites,
            products, and services.
          </li>
          <li>
            to ensure the use of our products and services is licensed and in
            compliance with applicable law.
          </li>
          <li>
            to send push notifications to your mobile device to communicate with
            you about our service. You can choose to disable such notifications
            via the settings in the app at your discretion.
          </li>
          <li>
            for any related purpose that you would reasonably expect, such as
            general administrative functions.
          </li>
        </ul>
        <p>
          We may aggregate personal information in a manner that makes it
          non­personal information, removing portions of the information that
          identify you as a specific person. For example, we may aggregate the
          zip code information of our customers to better understand the usage
          of our products and services. We may use aggregated or other
          non-personal information for any purpose, including sharing it with
          third parties. If we combine personal information with non­personal
          information, we treat the combined information as personal
          information.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Security</p>
        <p>
          It is our policy to protect your Content and personal information
          against unauthorized access or release. However, we cannot guarantee
          the security of our database, nor can we guarantee that information
          you supply won’t be intercepted while being transmitted to us over the
          Internet, and any information you transmit to us you do at your own
          risk. We recommend that you use unique numbers, letters and special
          characters in your password and not disclose your password to anyone.
          If you do share your password or personal information with others, you
          are responsible for all actions taken in the name of your account. If
          your password has been compromised for any reason, you should
          immediately notify IrisVR at{' '}
          <a href="mailto:security@thewild.com">security@thewild.com</a> and
          change your password.
        </p>
        <p>
          In addition, we have procedures that limit IrisVR’s employees and
          contractors’ access to personal information. Only those employees and
          contractors with a business reason to know have access to such
          information. Our Corporate Data Protection Policy lays out strict
          requirements for processing personal data pertaining to customers,
          prospects, business partners and employees. It meets the requirements
          of the European Data Protection Directive and ensures compliance with
          the principles of national and international data protection laws in
          force all over the world.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Sharing of Personal Information and Content</p>
        <p>
          We may share your Content and personal information within IrisVR for
          purposes of data processing or storage and to guide decisions about
          products, services, and communications. We do not rent, sell, or
          otherwise disclose your Content or personal information to third
          parties, unless we have your permission to do so or except under the
          following circumstances:
        </p>
        <ul>
          <li>
            <span className="emphasis">Authorized Third Parties.</span> We may
            share your Content and personal information with business partners,
            service providers, vendors, licensees, and other authorized
            third-party agents or contractors who help us with our business
            operations, including the storage and processing of personal
            information for the uses listed in this Privacy Policy (“Authorized
            Third Parties”). We require that Authorized Third Parties to agree
            to implement reasonable safeguards to protect the Content and
            personal information, and to use the personal information only for
            the purposes for which it was provided.
          </li>
          <li>
            <span className="emphasis">Scope file codes.</span> For certain
            products, you will have the option to create file-specific or
            folder-specific codes for your Content that can be freely shared and
            used in the Scope mobile application to connect and view the
            specific Content file. These codes allow any person to access and
            view your files and we do not encrypt or otherwise protect access to
            your Content in our software.
          </li>
          <li>
            <span className="emphasis">Compliance with law.</span> We may
            disclose your Content and personal information to comply with
            applicable laws, legal processes, subpoenas or court orders, and/or
            to establish or exercise our legal rights or defend against legal
            claims, or as otherwise required by law (including laws outside your
            state or country or other jurisdiction of residence).
          </li>
          <li>
            <span className="emphasis">
              Enforcement of rights and protection.
            </span>{' '}
            We may disclose Content and personal information and other
            information available to us when we believe, in our sole discretion,
            disclosure is necessary or appropriate in order to investigate,
            prevent, or take action regarding: illegal activities; suspected
            fraud; intellectual property protection matters; threats to the
            safety of any person; violation of our terms of service or end user
            license agreements. Please refer to our Copyright Policy in our EULA
            for more information on how we handle allegedly infringing Content.
          </li>
          <li>
            <span className="emphasis">
              Merger, acquisition, bankruptcy and other transactions.
            </span>{' '}
            If IrisVR should merge with another company, file for bankruptcy, or
            sell or reorganize some part or all of its business, we may disclose
            your personal information to prospective or actual purchasers. We
            may also share personal information about you with our lawyers,
            accountants, bankers, consultants and other advisors in connection
            with such transactions.
          </li>
        </ul>
        <p>
          If you publicly share or post any of your Content or personal
          information, please remember that the information you provide may be
          made available to others, and can be accessed and read by other users.
          IrisVR has no responsibility for any Content or information you
          disclose in any public forums.
        </p>
        <p>
          If we intend to post comments, endorsements or reviews which contain
          your personal information, we will ask your permission before doing
          so.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Transfer of personal information</p>
        <p>
          We (or an Authorized Third Party) may internally transfer, use,
          process, and store personal information about you in the United States
          or other countries. If you are located the European Union or other
          jurisdictions with laws governing data collection and use, please note
          that by providing your personal information you are agreeing to the
          transfer of your personal information to the United States to Wild
          Technology, Inc. or its Authorized Third Parties and to other
          countries where IrisVR, and Authorized Third Parties acting on our
          behalf operate, and to the use, processing and storage of such
          information as described in this Privacy Policy. Privacy protections
          vary from jurisdiction to jurisdiction and may not be as strong as the
          privacy laws of your country.
        </p>
        <p>
          We have offices in the United States. The European Commission has made
          an “adequacy decision” with respect to the data protection laws of
          this country. Transfers to the United States will be protected by
          appropriate safeguards, namely the use of standard data protection
          clauses adopted or approved by the European Commission, a copy of
          which can be obtained from{' '}
          <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">
            https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en.
          </a>
          .
        </p>
      </div>
      <div className="textblock">
        <p className="header">
          Retention and deletion of Content and personal information
        </p>
        <p>
          We will retain Content and personal information about you only as long
          as we need it to honor your choices, fulfill the purposes described in
          this Privacy Policy, or as required or permitted by applicable law. We
          may take reasonable measures to destroy or permanently de-­identify
          personal information in a secure manner when it is no longer required.
        </p>
        <p>
          Once you cancel your account or subscription, you will have access to
          your account and Content for the remainder of the term of your
          cancelled subscription, and any authorized users of your account will
          also be able to view Content you have shared with them. Once the
          subscription term has terminated, you will no longer have any access
          to your account or Content, however, IrisVR may retain your Content
          and account information unless you contact us at{' '}
          <a href="mailto:security@thewild.com">security@thewild.com</a> and
          request full deletion of your account. We will not share your Content
          or personal information (except as provided by this Privacy Policy),
          but your information will stay on our servers unless you contact us as
          indicated above.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Third party sites</p>
        <p>
          We may offer links to third party sites or provide social media
          features through our products, services, and websites that allow you
          to share information with your social networks and to interact with
          IrisVR on social media platforms. Your use of these other sites and
          platforms may enable them to collect data from you and place their own
          files on your computer (including cookies and other data collection
          technologies). IrisVR does not control third party sites and is not
          responsible for the privacy practices of third parties. You should
          review the privacy policies and settings of all websites or services
          that you visit.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Preferences, choices, and rights</p>
        <p>
          Please help us ensure the personal information we maintain about you
          is accurate, complete and up to date. You may have the right under
          applicable laws to access the personal information that IrisVR keeps
          for you, on request to IrisVR, except in certain limited circumstances
          (for example, where an applicable law requires us to deny such
          access).
        </p>
        <p>
          You can use the links provided below or contact us by e­mail or postal
          mail to request access to your personal information.
        </p>
        <p>
          <span className="emphasis">Account profile.</span> IrisVR provides a
          single sign ­in account that enables you to sign in to access multiple
          IrisVR applications. You can access and update your IrisVR account
          profile by logging in and following the online instructions.
        </p>
        <p>
          <span className="emphasis">Marketing preferences.</span> You can
          access and update our personal information and preferences, or
          unsubscribe from IrisVR marketing communications, using the
          unsubscribe instructions in communications you receive or through your
          account settings at www.irisvr.com/settings/overview. Note that you
          will still receive certain communications regarding your account and
          the IrisVR products that convey information about our services, and
          you cannot opt out of such communications as they are necessary to
          provide our products and services.
        </p>
        <p>
          <span className="emphasis">CA Residents.</span> California residents
          may choose to request certain information regarding our disclosure of
          personal information to third parties for their direct marketing
          purposes or choose to opt out of such disclosure. Our policy is not to
          disclose personal information collected online to a third party for
          directing marketing without your approval. To make a request or to opt
          out at any time, please contact us{' '}
          <a href="mailto:info@irisvr.com">info@irisvr.com</a>. It is important
          to note that this opt-out does not prohibit disclosures made for
          non-marketing purposes.
        </p>
        <p>
          <span className="emphasis">
            Your rights if you are an individual within the European Union (EU).
          </span>{' '}
          In this section, we have summarized the rights that you have under the
          General Data Protection Regulation (GDPR). Some of the rights are
          complex, and not all of the details have been included in our
          summaries. Accordingly, you should read the relevant laws and guidance
          from the regulatory authorities for a full explanation of these
          rights.
        </p>
        <ul>
          <li>
            Your principal rights under data protection law are:
            <ul>
              <li>the right to access;</li>
              <li>the right to rectification;</li>
              <li>the right to erasure;</li>
              <li>the right to restrict processing;</li>
              <li>the right to object to processing;</li>
              <li>the right to data portability;</li>
              <li>the right to complain to a supervisory authority; and</li>
              <li>the right to withdraw consent.</li>
            </ul>
          </li>
          <li>
            You have the right to confirmation as to whether or not we process
            your personal data and, where we do, access to the personal data,
            together with certain additional information. That additional
            information includes details of the purposes of the processing, the
            categories of personal data concerned and the recipients of the
            personal data. Providing the rights and freedoms of others are not
            affected, we will supply to you a copy of your personal data. The
            first copy will be provided free of charge, but additional copies
            may be subject to a reasonable fee.
          </li>
          <li>
            You have the right to have any inaccurate personal data about you
            rectified and, taking into account the purposes of the processing,
            to have any incomplete personal data about you completed.
          </li>
          <li>
            In some circumstances you have the right to the erasure of your
            personal data without undue delay. Those circumstances include: the
            personal data are no longer necessary in relation to the purposes
            for which they were collected or otherwise processed; you withdraw
            consent to consent-based processing; you object to the processing
            under certain rules of applicable data protection law; the
            processing is for direct marketing purposes; and the personal data
            have been unlawfully processed. However, there are exclusions of the
            right to erasure. The general exclusions include where processing is
            necessary: for exercising the right of freedom of expression and
            information; for compliance with a legal obligation; or for the
            establishment, exercise or defence of legal claims.
          </li>
          <li>
            In some circumstances you have the right to restrict the processing
            of your personal data. Those circumstances are: you contest the
            accuracy of the personal data; processing is unlawful but you oppose
            erasure; we no longer need the personal data for the purposes of our
            processing, but you require personal data for the establishment,
            exercise or defence of legal claims; and you have objected to
            processing, pending the verification of that objection. Where
            processing has been restricted on this basis, we may continue to
            store your personal data. However, we will only otherwise process
            it: with your consent; for the establishment, exercise or defence of
            legal claims; for the protection of the rights of another natural or
            legal person; or for reasons of important public interest.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            on grounds relating to your particular situation, but only to the
            extent that the legal basis for the processing is that the
            processing is necessary for: the performance of a task carried out
            in the public interest or in the exercise of any official authority
            vested in us; or the purposes of the legitimate interests pursued by
            us or by a third party. If you make such an objection, we will cease
            to process the personal information unless we can demonstrate
            compelling legitimate grounds for the processing which override your
            interests, rights and freedoms, or the processing is for the
            establishment, exercise or defence of legal claims.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            for direct marketing purposes (including profiling for direct
            marketing purposes). If you make such an objection, we will cease to
            process your personal data for this purpose.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            for scientific or historical research purposes or statistical
            purposes on grounds relating to your particular situation, unless
            the processing is necessary for the performance of a task carried
            out for reasons of public interest.
          </li>
          <li>
            To the extent that the legal basis for our processing of your
            personal data is:
            <ul>
              <li>consent; or</li>
              <li>
                that the processing is necessary for the performance of a
                contract to which you are party or in order to take steps at
                your request prior to entering into a contract, and such
                processing is carried out by automated means, you have the right
                to receive your personal data from us in a structured, commonly
                used and machine-readable format. However, this right does not
                apply where it would adversely affect the rights and freedoms of
                others.
              </li>
            </ul>
          </li>
          <li>
            If you consider that our processing of your personal information
            infringes data protection laws, you have a legal right to lodge a
            complaint with a supervisory authority responsible for data
            protection. You may do so in the EU member state of your habitual
            residence, your place of work or the place of the alleged
            infringement.
          </li>
          <li>
            To the extent that the legal basis for our processing of your
            personal information is consent, you have the right to withdraw that
            consent at any time. Withdrawal will not affect the lawfulness of
            processing before the withdrawal.
          </li>
          <li>
            You may exercise any of your rights in relation to your personal
            data by written notice to us using the contact information below.
          </li>
        </ul>
      </div>
      <div className="textblock">
        <p className="header">Privacy Policy updates</p>
        <p>
          We reserve the right to change this Privacy Policy from time to time
          at our sole discretion, consistent with applicable laws. We will post
          the most recent revision date at the top of this Privacy Policy.
        </p>
        <p>
          If we make material changes to our Privacy Policy, we will notify you
          by placing a notice on www.IrisVR.com and/or by sending you a notice
          to the e­mail address (if any) we have on file for you. We may
          supplement this process by sending notices through our products and
          services and on other IrisVR websites. The revised Privacy Policy will
          supersede and replace any previous privacy policies or statements
          effective as of the date on which it is posted. You should
          periodically check www.IrisVR.com and this Privacy Policy for updates.
        </p>
      </div>
      <div className="textblock">
        <p className="header">Contact Us</p>
        <p>
          You can contact us via e­mail at{' '}
          <a href="mailto:security@thewild.com">security@thewild.com</a>. Please
          include your contact information, the name of the IrisVR website,
          product, communication, or other service about which you are
          contacting us, and a detailed description of your specific request. We
          will comply with your request as soon as reasonably practicable.
        </p>
        <p>
          Our Data Protection Officer can be reached at{' '}
          <a href="mailto:security@thewild.com">security@thewild.com</a>.
        </p>
      </div>
    </div>
  </div>
)

export default props => (
  <Layout>
    <PrivacyPolicy {...props} />
  </Layout>
)

export { helmetProps }
